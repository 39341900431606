import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s08_1_2_1 : false,
      s08_2_2_1 : false,
      s08_2_2_2 : false,
      s08_2_2_3 : false,
      s08_2_2_4 : false,
      s08_3_2_1 : false,
      s08_4_2_1 : false
    }

    this.searchInit = {
      filter1Init: 4,
      filter2Init: 2,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>再エネコスト予測に基づく地域別の合成燃料製造コスト（2050年）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_1_2_1"}>
                <img src={'../../images/chart/s08-1-2-1.png'} alt="再エネコスト予測に基づく地域別の合成燃料製造コスト（2050年）" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_1_2_1}
                close={this.closeModal}
                name={"s08_1_2_1"}
                src={'/images/chart/s08-1-2-1.png'}
                alt="再エネコスト予測に基づく地域別の合成燃料製造コスト（2050年）"
                width={559}
                height={423} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.nedo.go.jp/library/database_index.html" target="_blank" rel="noreferrer noopener">NEDO（2020）「CO2からの液体燃料製造技術に関する開発シーズ発掘のための調査」 </a></p>
                <p className={styles.chartExplain}>出典元の閲覧には、NEDO成果報告書データベースでユーザー登録の上、調査件名を検索ください。</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>HEFA方式によるSAF製造コストの推移</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_2_2_1"}>
                <img src={'../../images/chart/s08-2-2-1.png'} alt="HEFA方式によるSAF製造コストの推移" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_2_2_1}
                close={this.closeModal}
                name={"s08_2_2_1"}
                src={'/images/chart/s08-2-2-1.png'}
                alt="HEFA方式によるSAF製造コストの推移"
                width={821}
                height={556} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://jp.weforum.org/reports/clean-skies-for-tomorrow-sustainable-aviation-fuels-as-a-pathway-to-net-zero-aviation/" target="_blank" rel="noreferrer noopener">World Economic Forum（2020）「Clean Skies for Tomorrow Sustainable Aviation Fuels as a Pathway to Net-Zero Aviation」</a></p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>ATJ方式によるSAF製造コストの推移</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_2_2_2"}>
                <img src={'../../images/chart/s08-2-2-2.png'} alt="ATJ方式によるSAF製造コストの推移" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_2_2_2}
                close={this.closeModal}
                name={"s08_2_2_2"}
                src={'/images/chart/s08-2-2-2.png'}
                alt="ATJ方式によるSAF製造コストの推移"
                width={821}
                height={597} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://jp.weforum.org/reports/clean-skies-for-tomorrow-sustainable-aviation-fuels-as-a-pathway-to-net-zero-aviation/" target="_blank" rel="noreferrer noopener">World Economic Forum（2020）「Clean Skies for Tomorrow Sustainable Aviation Fuels as a Pathway to Net-Zero Aviation」</a></p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>FT方式によるSAF製造コストの推移</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_2_2_3"}>
                <img src={'../../images/chart/s08-2-2-3.png'} alt="FT方式によるSAF製造コストの推移" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_2_2_3}
                close={this.closeModal}
                name={"s08_2_2_3"}
                src={'/images/chart/s08-2-2-3.png'}
                alt="FT方式によるSAF製造コストの推移"
                width={832}
                height={547} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://jp.weforum.org/reports/clean-skies-for-tomorrow-sustainable-aviation-fuels-as-a-pathway-to-net-zero-aviation/" target="_blank" rel="noreferrer noopener">World Economic Forum（2020）「Clean Skies for Tomorrow Sustainable Aviation Fuels as a Pathway to Net-Zero Aviation」</a></p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>SAF収率</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_2_2_4"}>
                <img src={'../../images/chart/s08-2-2-4.png'} alt="SAF収率" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_2_2_4}
                close={this.closeModal}
                name={"s08_2_2_4"}
                src={'/images/chart/s08-2-2-4.png'}
                alt="SAF収率"
                width={819}
                height={400} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://jp.weforum.org/reports/clean-skies-for-tomorrow-sustainable-aviation-fuels-as-a-pathway-to-net-zero-aviation/" target="_blank" rel="noreferrer noopener">World Economic Forum（2020）「Clean Skies for Tomorrow Sustainable Aviation Fuels as a Pathway to Net-Zero Aviation」</a></p>
                <p className={chartStyles.chartExplain}>注釈）i）エタノール方式、ii）総生産量（航空・道路用燃料含む）の原料に対する収率、iii）逆シフト法による収率ⅳ）ガソリンあるいはディーゼル、ⅴ）LPG、ナフサ等</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>合成メタン製造コストの低減イメージ</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_3_2_1"}>
                <img src={'../../images/chart/s08-3-2-1.png'} alt="合成メタン製造コストの低減イメージ" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_3_2_1}
                close={this.closeModal}
                name={"s08_3_2_1"}
                src={'/images/chart/s08-3-2-1.png'}
                alt="合成メタン製造コストの低減イメージ"
                width={932}
                height={524} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.meti.go.jp/shingikai/energy_environment/methanation_suishin/kaigai_tf/pdf/003_04_00.pdf" target="_blank" rel="noreferrer noopener">日本ガス協会（2022） コスト差を踏まえた支援策の検討について</a></p>
                <p className={chartStyles.chartExplain}>電力コスト：再エネ電力購入費用<br />
                設備費：メタネーションプラント建設費<br />
                CO2回収等：CO2費用、輸送費、運転費等</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>グリーンLPGの製造コスト見立</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s08_4_2_1"}>
                <img src={'../../images/chart/s08-4-2-1.png'} alt="グリーンLPGの製造コスト見立" width={559} />
              </button>
              <ImageBox
                open={this.state.s08_4_2_1}
                close={this.closeModal}
                name={"s08_4_2_1"}
                src={'/images/chart/s08-4-2-1.png'}
                alt="グリーンLPGの製造コスト見立"
                width={908}
                height={358} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.j-lpgas.gr.jp/news/NewsRelease_20220801.pdf" target="_blank" rel="noreferrer noopener">全国LPガス協会（2022）「CNに向けたLPガス流通事業者の取り組みについて」</a></p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}></div>
          </div>
        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`